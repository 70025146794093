var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row mt-4" },
    [
      _c("GolferSelector", {
        staticClass: "col-12 col-md-3",
        attrs: { selectedGolfer: _vm.selectedGolferId, golfers: _vm.golfers },
        on: {
          updateGolfer: function ($event) {
            return _vm.onGetGolfer($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "col-12 mx-auto align-middle" },
        [
          _vm.selectedGolferId && _vm.roundReviews
            ? _c("RoundsSummaryTable", {
                attrs: {
                  roundReviews: _vm.roundReviews,
                  userid: _vm.selectedGolferId,
                },
                on: {
                  onNavTo: function ($event) {
                    return _vm.onGetNavTo($event)
                  },
                  handicapChart: _vm.showModal,
                },
              })
            : _vm._e(),
          _vm.selectedGolferId
            ? _c("RoundsTable", {
                attrs: { filters: _vm.filters, roundsInfo: _vm.roundsInfo },
                on: {
                  updateFilters: function ($event) {
                    return _vm.onGetFilters($event)
                  },
                  "round-delete": function ($event) {
                    return _vm.onRoundUpdated($event)
                  },
                  "round-edit": function ($event) {
                    return _vm.onRoundUpdated($event)
                  },
                  "score-click": function ($event) {
                    return _vm.getScoreCard($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { title: "Player Handicap Chart" },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _vm.handicapData && _vm.antiHandicapData
            ? _c("PlayerHandicapChart", {
                attrs: {
                  data: _vm.handicapData,
                  antiHandicap: _vm.antiHandicapData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { title: "Score Card" },
          on: { hidden: _vm.resetScoreCard },
          model: {
            value: _vm.showScoreCard,
            callback: function ($$v) {
              _vm.showScoreCard = $$v
            },
            expression: "showScoreCard",
          },
        },
        [
          _vm.showScoreCard
            ? _c("ScoreCard", { attrs: { g2tId: _vm.selectedRoundGtid } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }